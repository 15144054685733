<template>
  <div class="card">
    <div class="card-body p-10">
      <div
        class="
          notice
          d-flex
          bg-light-warning
          rounded
          border-warning border border-dashed
          mb-10
          p-6
        "
      >
        <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
          <inline-svg src="media/icons/duotune/general/gen044.svg" />
        </span>
        <!--begin::Wrapper-->
        <div class="d-flex flex-stack flex-grow-1">
          <!--begin::Content-->
          <div class="fw-bold">
            <h4 class="text-gray-800 fw-bolder">新增價格方案說明</h4>
            <div class="fs-4 text-gray-600">
              1. 請先依序完成基本設定、購買設定、使用設定 <br />
              2. 完成設定後點擊右方新增即可 <br />
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Wrapper-->
      </div>
      <div class="row">
        <div class="col">
          <ul class="nav mb-10">
            <li class="nav-item">
              <a
                class="
                  nav-link
                  btn btn-lg btn-color-muted btn-active btn-active-light-primary
                  active
                  fw-bolder
                  px-4
                  me-1
                "
                id="addChargePlanTab_a1"
                data-bs-toggle="tab"
                href="#addChargePlanTab_1"
                >基本設定</a
              >
            </li>

            <li class="nav-item">
              <a
                class="
                  nav-link
                  btn btn-lg btn-color-muted btn-active btn-active-light-primary
                  fw-bolder
                  px-4
                  me-1
                "
                id="addChargePlanTab_a2"
                data-bs-toggle="tab"
                href="#addChargePlanTab_2"
                >購買設定</a
              >
            </li>

            <li class="nav-item">
              <a
                class="
                  nav-link
                  btn btn-lg btn-color-muted btn-active btn-active-light-primary
                  fw-bolder
                  px-4
                "
                id="addChargePlanTab_a3"
                data-bs-toggle="tab"
                href="#addChargePlanTab_3"
                >使用設定</a
              >
            </li>
          </ul>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <button
            type="button"
            class="btn btn-primary ms-3"
            style="height: 45.44px"
            @click="onSubmit()"
          >
            新增
          </button>
        </div>
      </div>

      <div class="tab-content px-10">
        <div class="tab-pane fade active show" id="addChargePlanTab_1">
          <div class="table-responsive">
            <el-form
              id="basicSetUpForm"
              label-position="left"
              @submit.prevent="onSubmit()"
              :model="basicData"
              :rules="rules"
              ref="basicSetUpRef"
              class="form"
              label-width="140px"
            >
              <!-- 方案名稱 -->
              <el-form-item prop="title">
                <template #label> 方案名稱 </template>
                <el-input
                  v-model="basicData.title"
                  placeholder="請輸入價格方案名稱"
                />
              </el-form-item>
              <!-- 使用設施 -->
              <el-form-item prop="facility">
                <template #label> 使用設施 </template>
                <el-select
                  v-model="basicData.facility"
                  filterable
                  placeholder="請選擇設施"
                  style="width: 400px"
                >
                  <el-option
                    v-for="item in facilityOption"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <!-- 方案類型 -->
              <el-form-item>
                <template #label> 方案類型 </template>
                <el-radio
                  v-model="basicData.timeMode"
                  label="Minutes"
                  size="large"
                  >時租</el-radio
                >
                <el-radio v-model="basicData.timeMode" label="Days" size="large"
                  >日租
                  <el-switch
                    class="ms-1"
                    v-if="basicData.timeMode == 'Days'"
                    v-model="buySetting.isShift"
                    active-text="飯店模式"
                  ></el-switch>
                </el-radio>
                <i class="d-block mt-1"
                  >此設定將會影響後續設定選項(日租-飯店模式後續設定可調整入住與翌日退房時間)</i
                >
              </el-form-item>
              <!-- 預約模式 -->
              <el-form-item v-if="isItemShow('reservationMode')">
                <template #label> 預約模式 </template>
                <el-select
                  v-model="basicData.reservationMode"
                  placeholder="請選擇預約模式"
                >
                  <el-option label="固定模式" value="Fixed" />
                  <el-option label="按起始刻度重複" value="Repeated" />
                </el-select>
              </el-form-item>
              <!-- 方案描述 -->
              <el-form-item>
                <template #label> 方案描述 </template>
                <el-input
                  v-model="basicData.description"
                  placeholder="請輸入價格方案描述"
                />
              </el-form-item>
              <!-- 方案使用須知 -->
              <el-form-item>
                <template #label> 使用須知 </template>
                <el-input
                  v-model="basicData.usageNotice"
                  placeholder="請輸入使用須知"
                />
              </el-form-item>
              <!-- 方案生效日期 -->
              <el-form-item>
                <template #label> 方案生效日期 </template>
                <el-date-picker
                  v-model="basicData.beginTime"
                  type="datetime"
                  placeholder="請選擇生效日期"
                />
              </el-form-item>
              <!-- 方案結束日期 -->
              <el-form-item>
                <template #label> 方案結束日期 </template>
                <el-radio
                  v-model="basicData.endTimeRadio"
                  label="notLimit"
                  size="large"
                  >不限制</el-radio
                >
                <el-radio
                  v-model="basicData.endTimeRadio"
                  label="limit"
                  size="large"
                  ><span class="me-5">結束日期</span>
                  <el-date-picker
                    v-if="basicData.endTimeRadio == 'limit'"
                    v-model="basicData.endTime"
                    type="datetime"
                    placeholder="請選擇結束日期"
                /></el-radio>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="tab-pane fade" id="addChargePlanTab_2">
          <div class="table-responsive">
            <el-form
              id="purchaseSetUpForm"
              label-position="left"
              @submit.prevent="onSubmit()"
              :model="buySetting"
              :rules="rules"
              ref="purchaseSetUpRef"
              class="form"
              label-width="140px"
            >
              <!-- 貨幣單位 -->
              <el-form-item>
                <template #label> 貨幣單位 </template>
                <el-select
                  v-model="buySetting.currency"
                  placeholder="請選擇貨幣單位"
                >
                  <el-option
                    v-for="item in currencyOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <!-- 計價時間單位 -->
              <el-form-item v-if="isItemShow('chargeTime')">
                <template #label> 計價時間單位 </template>
                <el-radio
                  v-model="buySetting.chargeTimeRadio"
                  label="notLimit"
                  size="large"
                  :disabled="basicData.timeMode == 'Days'"
                  ><span class="me-5">建議選項</span
                  ><el-select
                    v-if="buySetting.chargeTimeRadio == 'notLimit'"
                    v-model="buySetting.chargeTime"
                    placeholder="請選擇計價時間單位"
                  >
                    <el-option
                      v-for="item in chargeTimeOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    /> </el-select
                ></el-radio>
                <el-radio
                  v-model="buySetting.chargeTimeRadio"
                  label="limit"
                  size="large"
                >
                  <span class="me-5">進階輸入</span>
                  <template v-if="buySetting.chargeTimeRadio == 'limit'">
                    <el-input
                      type="number"
                      style="width: 150px; vertical-align: middle"
                      v-model="buySetting.chargeTime"
                      :min="1"
                    >
                      <template #append>{{
                        timeModeUnitMap[basicData.timeMode]
                      }}</template>
                    </el-input>
                  </template>
                </el-radio>
                <i class="d-block mt-1"
                  >此設定將會影響最小與最大購買時間<br /><br />
                  範例:設定30分鐘，將以30分鐘為單位計價，最小購買時間只可設置為計價時間單位倍數(30、60、90、120...分鐘)</i
                >
              </el-form-item>
              <!-- 時間單位價格 -->
              <el-form-item v-if="isItemShow('amount')">
                <template #label> 時間單位價格 </template>
                <el-input
                  type="number"
                  style="width: 200px"
                  v-model="buySetting.amount"
                  :min="1"
                >
                  <template #append>{{
                    currencyMap[buySetting.currency]
                  }}</template>
                </el-input>
              </el-form-item>
              <!-- 原價標籤 -->
              <el-form-item>
                <template #label> 原價標籤 </template>
                <el-input
                  type="text"
                  style="width: 200px"
                  v-model="buySetting.priceTag"
                >
                  <template #append>{{
                    currencyMap[buySetting.currency]
                  }}</template>
                </el-input>
              </el-form-item>
              <!-- 起始刻度 -->
              <el-form-item v-if="isItemShow('repeatedTime')">
                <template #label> 起始刻度 </template>
                <div>
                  <el-select
                    v-model="buySetting.repeatedTime"
                    placeholder="請選擇起始刻度"
                  >
                    <el-option
                      v-for="item in repeatedTimeOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <i class="d-block mt-1"
                    >範例:設定30分鐘，可預約起始時間為01:00、01:30、02:00、02:30、03:00、03:30...</i
                  >
                </div>
              </el-form-item>

              <!-- 稅選項 -->
              <el-form-item v-if="isItemShow('tax')">
                <template #label> 稅選項 </template>
                <el-select
                  v-model="buySetting.tax"
                  placeholder="請選擇貨幣單位"
                >
                  <el-option
                    v-for="item in taxOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <!-- 最小購買時間 -->
              <el-form-item prop="minReservationTime">
                <template #label> 最小購買時間 </template>
                <el-radio
                  v-model="buySetting.minReservationTimeRadio"
                  label="notLimit"
                  size="large"
                  :disabled="basicData.timeMode == 'Days'"
                  ><span class="me-5">建議選項</span
                  ><el-select
                    v-if="buySetting.minReservationTimeRadio == 'notLimit'"
                    v-model="buySetting.minReservationTime"
                    placeholder="請選擇最小購買時間"
                  >
                    <el-option
                      v-for="item in minReservationTimeOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    /> </el-select
                ></el-radio>
                <el-radio
                  v-model="buySetting.minReservationTimeRadio"
                  label="limit"
                  size="large"
                >
                  <span class="me-5">進階輸入</span>
                  <template
                    v-if="buySetting.minReservationTimeRadio == 'limit'"
                  >
                    <el-input
                      type="number"
                      style="width: 150px; vertical-align: middle"
                      v-model="buySetting.minReservationTime"
                      :min="1"
                    >
                      <template #append>{{
                        timeModeUnitMap[basicData.timeMode]
                      }}</template>
                    </el-input>
                  </template>
                </el-radio>
              </el-form-item>
              <!-- 最大購買時間 -->
              <el-form-item prop="maxReservationTime">
                <template #label> 最大購買時間 </template>
                <el-radio
                  v-model="buySetting.maxReservationTimeRadio"
                  label="notLimit"
                  size="large"
                  >不限制</el-radio
                >
                <el-radio
                  v-model="buySetting.maxReservationTimeRadio"
                  label="limit"
                  size="large"
                  ><span class="me-5">限制時間</span>
                  <template
                    v-if="buySetting.maxReservationTimeRadio == 'limit'"
                  >
                    <el-input
                      type="number"
                      style="width: 150px; vertical-align: middle"
                      v-model="buySetting.maxReservationTime"
                      :min="1"
                    >
                      <template #append>{{
                        timeModeUnitMap[basicData.timeMode]
                      }}</template>
                    </el-input>
                  </template>
                </el-radio>
              </el-form-item>
              <!-- 最小購買數量 -->
              <el-form-item>
                <template #label> 最小購買數量 </template>
                <el-input
                  type="number"
                  style="width: 150px"
                  v-model="buySetting.minQuota"
                  :min="1"
                />
              </el-form-item>
              <!-- 最大購買數量 -->
              <el-form-item>
                <template #label> 最大購買數量 </template>
                <el-radio
                  v-model="buySetting.maxQuotaRadio"
                  label="notLimit"
                  size="large"
                  >不限制</el-radio
                >
                <el-radio
                  v-model="buySetting.maxQuotaRadio"
                  label="limit"
                  size="large"
                  ><span class="me-5">限制數量</span
                  ><el-input
                    type="number"
                    style="width: 150px"
                    v-if="buySetting.maxQuotaRadio == 'limit'"
                    v-model="buySetting.maxQuota"
                    :min="1"
                /></el-radio>
              </el-form-item>
              <!-- 入住&翌日時間 -->
              <el-form-item v-if="buySetting.isShift">
                <template #label> 入住&amp;翌日時間 </template>
                <el-time-picker
                  v-model="buySetting.localBeginTime"
                  :disabled-hours="disabledLocalBeginTimeHoursOption"
                  :disabled-minutes="disabledLocalBeginTimeMinutesOption"
                  :disabled-seconds="disabledLocalTimeSecondsOption"
                  placeholder="入住時間"
                />
                <span class="mx-2">至翌日</span>
                <el-time-picker
                  v-model="buySetting.localEndTime"
                  :disabled-hours="disabledLocalEndTimeHoursOption"
                  :disabled-minutes="disabledLocalEndTimeMinutesOption"
                  :disabled-seconds="disabledLocalTimeSecondsOption"
                  placeholder="翌日時間"
                />
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="tab-pane fade" id="addChargePlanTab_3">
          <div class="table-responsive">
            <el-form
              id="useSetUpForm"
              label-position="left"
              @submit.prevent="onSubmit()"
              :model="useSetting"
              :rules="rules"
              ref="useSetUpForm"
              class="form"
              label-width="160px"
            >
              <div class="row" style="width: 100%">
                <div class="col">
                  <el-form-item
                    style="padding: 15px 0; margin: 0"
                    label="可容納人數"
                  >
                    <el-input type="number" v-model="useSetting.capacity">
                      <template #append>人</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    style="padding: 15px 0; margin: 0"
                    label="可提前預約天數"
                  >
                    <el-input type="number" v-model="useSetting.availableDays">
                      <template #append>天</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    style="padding: 15px 0; margin: 0"
                    label="提前使用時間"
                  >
                    <el-input type="number" v-model="useSetting.minuteToEnter">
                      <template #append>分鐘</template>
                    </el-input>
                    <i>Ex.設定為15分鐘 為10:00開始的票券 可於 09:45開始使用</i>
                  </el-form-item>
                  <el-form-item
                    style="padding: 15px 0; margin: 0"
                    label="開始前不可取消時間"
                  >
                    <el-input type="number" v-model="useSetting.minuteToCancel">
                      <template #append>分鐘</template>
                    </el-input>
                    <i>Ex.設定為15分鐘 為10:00開始的票券 於 09:45後不可取消</i>
                  </el-form-item>
                  <el-form-item
                    style="padding: 15px 0; margin: 0"
                    label="結束後自動歸還"
                  >
                    <el-input
                      type="number"
                      v-model="useSetting.minuteToCheckOut"
                    >
                      <template #append>分鐘</template>
                    </el-input>
                    <i
                      >Ex.設定為15分鐘 為10:00結束的票券 於
                      10:15由系統執行自動歸還</i
                    >
                  </el-form-item>
                  <!-- 開始前保留時間 -->
                  <el-form-item style="padding: 15px 0; margin: 0">
                    <template #label> 開始前保留時間 </template>
                    <el-input
                      type="number"
                      v-model="useSetting.reserveBeforeBeginTime"
                      :min="0"
                      disabled
                      ><template #append>分鐘</template></el-input
                    >
                  </el-form-item>
                  <!-- 結束後保留時 -->
                  <el-form-item style="padding: 15px 0; margin: 0">
                    <template #label> 結束後保留時間 </template>
                    <el-input
                      type="number"
                      v-model="useSetting.reserveAfterEndTime"
                      :min="0"
                      disabled
                      ><template #append>分鐘</template></el-input
                    >
                  </el-form-item>
                </div>
                <div class="col">
                  <div>
                    <i>(以下調整均不影響已經開立的票券)</i>
                  </div>
                  <div class="my-7">
                    <el-switch
                      v-model="useSetting.autoRelease"
                      active-text="歸還動作"
                      :active-value="false"
                      :inactive-value="true"
                    >
                    </el-switch>
                    <br />
                    <i>執行歸還後是否可再次使用</i>
                  </div>
                  <div class="my-7">
                    <el-switch
                      v-model="useSetting.canDoorAccess"
                      active-text="開門權限"
                    >
                    </el-switch>
                    <br />
                    <i>預定此設施/設備 是否擁有開門權限</i>
                  </div>
                  <div class="my-7">
                    <el-switch
                      v-model="useSetting.canIotFetch"
                      active-text="iot權限"
                    >
                    </el-switch>
                    <br />
                    <i>預定此設施/設備 是否擁有iot權限</i>
                  </div>
                  <div class="my-7">
                    <el-switch
                      v-model="useSetting.canDistribute"
                      active-text="轉贈功能"
                    >
                    </el-switch>
                    <br />
                    <i>預定此設施/設備 是否可使用轉贈功能</i>
                  </div>
                  <div class="my-7">
                    <el-switch
                      v-model="useSetting.canInvite"
                      active-text="邀請功能"
                    >
                    </el-switch>
                    <br />
                    <i
                      >預定此設施/設備 是否可使用邀請功能
                      接受邀請方會取得開門權限</i
                    >
                  </div>
                  <div class="my-7">
                    <el-switch
                      v-model="useSetting.canUserCheckOut"
                      active-text="使用者自行歸還"
                    >
                    </el-switch>
                    <br />
                    <i>預定此設施/設備 是否可以讓Spaces Go使用者自行歸還</i>
                  </div>
                  <div class="my-7">
                    <el-switch
                      v-model="useSetting.canUserCheckIn"
                      active-text="使用者自行報到"
                    >
                    </el-switch>
                    <br />
                    <i>預定此設施/設備 是否可以讓Spaces Go使用者自行報到</i>
                  </div>
                  <div class="my-7">
                    <el-switch
                      v-model="useSetting.canUserChangeSeat"
                      active-text="使用者自行更換座位"
                    >
                    </el-switch>
                    <br />
                    <i>預定此設施/設備 是否可以讓Spaces Go使用者自行更換座位</i>
                  </div>
                  <div class="my-7">
                    <el-switch
                      v-model="useSetting.canUserFeedback"
                      active-text="使用者填寫回饋與評分"
                    >
                    </el-switch>
                    <br />
                    <i>預定此設施 是否可以讓Spaces Go使用者填寫回饋及評分</i>
                  </div>
                  <template v-if="useSetting.canUserFeedback">
                    <div class="my-7">
                      <div>可回饋起始時間:</div>
                      <el-date-picker
                        v-model="useSetting.feedbackBeginTime"
                        type="datetime"
                      ></el-date-picker>
                      <br />
                      <i>此設施在此時間後，才可進行回饋</i>
                    </div>
                    <div class="my-7">
                      <div>可回饋持續天數:</div>
                      <el-input
                        type="number"
                        v-model="useSetting.feedbackDuration"
                      >
                        <template #append>天</template>
                      </el-input>
                      <br />
                      <i>票券超過此天數後，將不能進行回饋與收到回饋通知</i>
                    </div>
                  </template>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import localstorage from "@/core/services/LocalstorageService";
import { getSupportedTaxes } from "@/core/services/api/taxes";
import { addFacilityChargePlan } from "@/core/services/api/chargePlan";
import {
  getUtcTimeString,
  getShiftBeginTimeString,
  getShiftEndTimeString,
  getDefaultTimeString,
  getDefaultEndTimeString
} from "@/core/utils/timeString";
export default defineComponent({
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const basicSetUpRef = ref<null | HTMLFormElement>(null);
    const purchaseSetUpRef = ref<null | HTMLFormElement>(null);
    const useSetUpForm = ref<null | HTMLFormElement>(null);
    const basicData = reactive({
      title: "",
      facility: "",
      timeMode: "Minutes",
      reservationMode: "Repeated",
      description: "",
      usageNotice: "",
      beginTime: new Date(),
      endTimeRadio: "notLimit",
      endTime: new Date(),
    });
    const buySetting = reactive({
      priceTag: "",
      currency: "TWD",
      chargeTimeRadio: "notLimit",
      chargeTime: 0,
      amount: 10,
      repeatedTime: 30,
      tax: "b98A6iqKXk-_sSFXFYoCKQ", //預設消費稅guid
      minReservationTimeRadio: "notLimit",
      minReservationTime: 30,
      maxReservationTimeRadio: "notLimit",
      maxReservationTime: -1,
      minQuota: 1,
      maxQuotaRadio: "notLimit",
      maxQuota: -1,
      isShift: false,
      localBeginTime: 0,
      localEndTime: 0,
    });
    const useSetting = reactive({
      capacity: 1,
      availableDays: 30,
      minuteToEnter: 10,
      minuteToCancel: 30,
      minuteToCheckOut: 15,
      reserveBeforeBeginTime: 0,
      reserveAfterEndTime: 0,
      autoRelease: false,
      canDoorAccess: true,
      canIotFetch: true,
      canDistribute: true,
      canInvite: false,
      canUserCheckOut: true,
      canUserCheckIn: true,
      canUserChangeSeat: true,
      canUserFeedback: false,
      feedbackBeginTime: "",
      feedbackDuration: 20,
    });

    const verifyMinReservationTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("請輸入最小購買時間"));
      } else if (value < buySetting.chargeTime) {
        return callback(new Error("最小購買時間必須大於計價時間單位"));
      } else if (value % buySetting.chargeTime !== 0) {
        return callback(new Error("最小購買時間必須是計價時間單位的倍數"));
      } else {
        callback();
      }
    };
    const verifyMaxReservationTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("請輸入最大購買時間"));
      } else if (value == -1) {
        callback();
      } else if (value < buySetting.chargeTime) {
        return callback(new Error("最大購買時間必須大於或等於最小購買時間"));
      } else if (value < buySetting.minReservationTime) {
        return callback(new Error("最大購買時間必須大於計價時間單位"));
      } else if (value % buySetting.chargeTime !== 0) {
        return callback(new Error("最大購買時間必須是計價時間單位的倍數"));
      } else {
        callback();
      }
    };
    const rules = reactive({
      title: [
        {
          required: true,
          message: "請輸入價格方案名稱",
          trigger: "blur",
        },
      ],
      facility: [
        {
          required: true,
          message: "請選擇設施",
          trigger: "change",
        },
      ],
      minReservationTime: [
        { validator: verifyMinReservationTime, trigger: "change" },
      ],
      maxReservationTime: [
        { validator: verifyMaxReservationTime, trigger: "change" },
      ],
    });

    const facilityOption: any = reactive([]);
    const facilityTypeMap: any = reactive({
      Facility: "設施",
      Property: "設備",
    });
    // 計價時間單位選單
    const chargeTimeOption: any = reactive([]);
    //貨幣單位選單
    const currencyOption = reactive([
      { label: "免費", value: "Free" },
      { label: "點數", value: "Point" },
      { label: "台幣", value: "TWD" },
    ]);
    // 稅選單
    const taxOption: any = reactive([]);
    const timeModeUnitMap = {
      Minutes: "分鐘",
      Days: "天",
    };
    const currencyMap = {
      TWD: "台幣",
      Point: "點數",
    };
    const minReservationTimeOption: any = reactive([]);
    const minutesTimeOption = [
      { label: "30分鐘", value: 30 },
      { label: "60分鐘", value: 60 },
      { label: "兩小時", value: 120 },
      { label: "三小時", value: 180 },
      { label: "四小時", value: 240 },
    ];
    const daysTimeOption = [
      { label: "一小時", value: 1 },
      { label: "二小時", value: 2 },
      { label: "三小時", value: 3 },
      { label: "四小時", value: 4 },
      { label: "五小時", value: 5 },
    ];
    const repeatedTimeOption = [
      { label: "15分鐘", value: 15 },
      { label: "30分鐘", value: 30 },
      { label: "一小時", value: 60 },
    ];
    const formValidateObj = reactive({
      basicSetUp: false,
      purchaseSetUp: false,
      useSetUp: false,
    });
    const formValidateObjMap = reactive({
      basicSetUp: 1,
      purchaseSetUp: 2,
      useSetUp: 3,
    });

    // tab顯示驗證未過頁面
    const showNotPassTab = (tab) => {
      let tab1 = document.getElementById("addChargePlanTab_1");
      let tab2 = document.getElementById("addChargePlanTab_2");
      let tab3 = document.getElementById("addChargePlanTab_3");
      let tabA1 = document.getElementById("addChargePlanTab_a1");
      let tabA2 = document.getElementById("addChargePlanTab_a2");
      let tabA3 = document.getElementById("addChargePlanTab_a3");
      tab1?.classList.remove("active", "show");
      tab2?.classList.remove("active", "show");
      tab3?.classList.remove("active", "show");
      tabA1?.classList.remove("active");
      tabA2?.classList.remove("active");
      tabA3?.classList.remove("active");
      switch (tab) {
        case 1:
          tab1?.classList.add("active", "show");
          tabA1?.classList.add("active");
          break;
        case 2:
          tab2?.classList.add("active", "show");
          tabA2?.classList.add("active");
          break;
        case 3:
          tab3?.classList.add("active", "show");
          tabA3?.classList.add("active");
          break;
      }
    };

    const onSubmit = async () => {
      basicSetUpRef.value!.validate((valid) => {
        formValidateObj.basicSetUp = valid;
      });
      purchaseSetUpRef.value!.validate((valid) => {
        formValidateObj.purchaseSetUp = valid;
      });
      useSetUpForm.value!.validate((valid) => {
        formValidateObj.useSetUp = valid;
      });

      let allPass = true;
      for (const key in formValidateObj) {
        if (!formValidateObj[key]) {
          allPass = false;
          showNotPassTab(formValidateObjMap[key]);
          break;
        }
      }
      if (allPass) {
        console.log("buySetting.localBeginTime", buySetting.localBeginTime);
        console.log("buySetting.localEndTime", buySetting.localEndTime);
        await Swal.fire({
          title: "您確定要新增方案嗎?",
          confirmButtonText: "確定",
          showCancelButton: true,
          cancelButtonText: `取消`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            let includeTax =
              buySetting.currency == "TWD" &&
              buySetting.tax == "b98A6iqKXk-_sSFXFYoCKQ";
            let timeChargePlanBasicData = {
              title: basicData.title,
              description: basicData.description,
              timeMode: basicData.timeMode,
              reservationMode: basicData.reservationMode,
              beginTime: getUtcTimeString(basicData.beginTime),
              endTime: basicData.endTimeRadio=="notLimit"?getDefaultEndTimeString():getUtcTimeString(basicData.endTime),
              usageNotice: basicData.usageNotice,
              priceTag: buySetting.priceTag,
              chargeTime: buySetting.chargeTime,
              currency: buySetting.currency,
              amount: buySetting.amount,
              repeatedTime: buySetting.repeatedTime,
              tax: includeTax ? buySetting.tax : "",
              includeTax: includeTax,
              minReservationTime: buySetting.minReservationTime,
              maxReservationTime: buySetting.maxReservationTime,
              minQuota: buySetting.minQuota,
              maxQuota: buySetting.maxQuota,
              reservationSetting: {
                isShift: buySetting.isShift,
                localBeginTime: getShiftBeginTimeString(
                  buySetting.localBeginTime
                ),
                localEndTime: getShiftEndTimeString(buySetting.localEndTime),
                reserveBeforeBeginTime: useSetting.reserveBeforeBeginTime,
                reserveAfterEndTime: useSetting.reserveAfterEndTime,
              },
              setting: {
                invitationNumber: useSetting.capacity - 1,
                availableDays: useSetting.availableDays,
                minuteToEnter: useSetting.minuteToEnter,
                minuteToCancel: useSetting.minuteToCancel,
                minuteToCheckOut: useSetting.minuteToCheckOut,
                autoRelease: useSetting.autoRelease,
                canDoorAccess: useSetting.canDoorAccess,
                canIotFetch: useSetting.canIotFetch,
                canDistribute: useSetting.canDistribute,
                canInvite: useSetting.canInvite,
                canUserCheckOut: useSetting.canUserCheckOut,
                canUserCheckIn: useSetting.canUserCheckIn,
                canUserChangeSeat: useSetting.canUserChangeSeat,
                canUserFeedback: useSetting.canUserFeedback,
                feedbackBeginTime:
                  useSetting.feedbackBeginTime == ""
                    ? getDefaultTimeString()
                    : getUtcTimeString(useSetting.feedbackBeginTime),
                feedbackDuration: useSetting.feedbackDuration,
              },
            };

            console.log("timeChargePlanBasicData", timeChargePlanBasicData);

            await addFacilityChargePlan(
              basicData.facility,
              timeChargePlanBasicData
            ).then(async () => {
              await Swal.fire("新增成功!", "", "success");
            });
          }
        });
      }
    };

    // 設置所有設施
    const setFacilityOption = async () => {
      let facilities = await getFacilityBySpace(currentSpaceGuid!);
      console.log("facilities", facilities);
      for (const item of facilities) {
        facilityOption.push({
          name: `${facilityTypeMap[item.facility.type]} : ${
            item.facility.name
          }`,
          value: item.facility.guid,
          type: item.facility.type,
        });
      }
      facilityOption.splice(
        0,
        facilityOption.length,
        ...facilityOption.sort((a, b) => (a.type == "Facility" ? 1 : -1))
      );
    };

    // 設置稅選項
    const setTaxOption = async () => {
      taxOption.splice(0);
      let supportedTaxes = await getSupportedTaxes();
      taxOption.push({ value: "noTax", label: "不需稅率" });
      supportedTaxes.forEach((taxe) => {
        taxOption.push({ value: taxe.guid, label: taxe.description });
      });
      console.log("taxOption", taxOption);
    };

    // 判斷是否顯示欄位
    const isItemShow = (item) => {
      // console.log("basicData", basicData);
      switch (item) {
        case "chargeTime":
          return buySetting.currency !== "Free";
        case "amount":
          return buySetting.currency !== "Free";
        case "tax":
          return (
            buySetting.currency !== "Free" && buySetting.currency !== "Point"
          );
        case "repeatedTime":
        case "reservationMode":
          return basicData.timeMode == "Minutes";
      }
    };

    watch(
      () => basicData.timeMode,
      () => {
        switch (basicData.timeMode) {
          case "Minutes":
            chargeTimeOption.splice(
              0,
              chargeTimeOption.length,
              ...minutesTimeOption
            );
            buySetting.chargeTime = 30;
            break;
          case "Days":
            chargeTimeOption.splice(
              0,
              chargeTimeOption.length,
              ...daysTimeOption
            );
            buySetting.chargeTimeRadio = "limit";
            buySetting.minReservationTimeRadio = "limit";
            buySetting.chargeTime = 1;
            basicData.reservationMode = "Fixed";
            buySetting.repeatedTime = 0;
            break;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    // 設定最小購買時間選單
    const setMinReservationTimeOption = () => {
      switch (basicData.timeMode) {
        case "Minutes":
          minReservationTimeOption.splice(
            0,
            minReservationTimeOption.length,
            ...minutesTimeOption.filter((o) => o.value >= buySetting.chargeTime)
          );
          buySetting.minReservationTime = minReservationTimeOption[0].value;
          break;
        case "Days":
          minReservationTimeOption.splice(
            0,
            minReservationTimeOption.length,
            ...daysTimeOption.filter((o) => o.value >= buySetting.chargeTime)
          );
          buySetting.minReservationTime = minReservationTimeOption[0].value;
          break;
      }
    };

    watch(
      () => buySetting.chargeTime,
      () => {
        setMinReservationTimeOption();
      },
      {
        immediate: true,
      }
    );
    // 設定最大購買時間選單
    const setMaxReservationTime = () => {
      if (buySetting.maxReservationTimeRadio == "notLimit") {
        buySetting.maxReservationTime = -1;
      }
    };
    watch(
      () => buySetting.maxReservationTimeRadio,
      () => {
        setMaxReservationTime();
      },
      {
        immediate: true,
      }
    );
    // 設定金額
    const setAmount = () => {
      if (buySetting.currency == "Free") {
        buySetting.amount = 0;
      }
    };
    watch(
      () => buySetting.currency,
      () => {
        setAmount();
      },
      {
        immediate: true,
      }
    );

    const makeRange = (start: number, end: number) => {
      const result: number[] = [];
      for (let i = start; i <= end; i++) {
        result.push(i);
      }
      return result;
    };

    // 設置入住小時選單
    const disabledLocalBeginTimeHoursOption = () => {
      return makeRange(0, 11);
    };

    // 設置入住分鐘選單
    const disabledLocalBeginTimeMinutesOption = (hour) => {
      return [];
    };

    // 設置秒選單
    const disabledLocalTimeSecondsOption = () => {
      return makeRange(1, 59);
    };

    // 設置翌日小時選單
    const disabledLocalEndTimeHoursOption = () => {
      return makeRange(13, 23);
    };

    // 設置翌日分鐘選單
    const disabledLocalEndTimeMinutesOption = (hour) => {
      if (hour === 12) {
        return makeRange(1, 59);
      }
      return [];
    };

    const init = async () => {
      await setFacilityOption();
      await setTaxOption();
    };
    init();

    return {
      basicSetUpRef,
      purchaseSetUpRef,
      useSetUpForm,
      basicData,
      buySetting,
      useSetting,
      onSubmit,
      rules,
      facilityOption,
      chargeTimeOption,
      currencyOption,
      taxOption,
      isItemShow,
      timeModeUnitMap,
      currencyMap,
      minReservationTimeOption,
      repeatedTimeOption,
      disabledLocalBeginTimeHoursOption,
      disabledLocalBeginTimeMinutesOption,
      disabledLocalTimeSecondsOption,
      disabledLocalEndTimeHoursOption,
      disabledLocalEndTimeMinutesOption,
    };
  },
});
</script>

<style scoped>
.addChargePlan >>> .el-form-item__label {
  font-size: 18px;
  font-weight: 600;
}
.unit-text {
  font-size: 16px;
  color: #000;
}
</style>
